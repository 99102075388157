//Dependencies
import * as React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';

//Import App
import App from './App';

//Import Styling
import './index.scss';

/* ------------------------------------------ Set Root ------------------------------------------ */

const root = ReactDOM.createRoot(
  document.getElementById("root")
);

/* ------------------------------------------ Render Root ------------------------------------------ */

root.render(
  <React.StrictMode>
    <BrowserRouter >
      <App />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
